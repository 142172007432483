import React, {useState, useEffect, useContext} from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import LocaleContext from '../../contexts/LocaleContext';
import { useForm, SubmitHandler, set } from 'react-hook-form';
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../data/defaultFormat';
import ModalMessage from "./Modal";
import './styles.scss'

enum AntiquityEnum{
  'Menos de 1 año',
  'Entre 1 y 3 años',
  'Entre 3 y 5 años',
  'Más de 5 años',
}

enum TPVEnum {
  'Menos de 50,000 USD mensuales',
  'Entre 50,000 y 200,000 USD mensuales',
  'Entre 200,000 y 500,000 USD mensuales',
  'Entre 500,000 y 2,000,000 USD mensuales',
  'Más de 2,000,000 USD mensuales',
}

enum IndustryEnum{
  'Airlines',
  'Apps',
  'Construction',
  'Consulting',
  'Courrier',
  'Cripto & Forex',
  'Delivery',
  'Education',
  'Entretainment',
  'Finance',
  'Fitness',
  'Food & Beverage',
  'Gambling',
  'Gateway',
  'Govermnent',
  'Helthcare',
  'Insurance',
  'Media',
  'PSP',
  'Retail',
  'Technology',
  'Telecomunications',
  'Transportation',
  'Tourism',
  'Utilities'
}

interface IFormInput {
  name: string;
  email: string;
  enterprise: string;
  countries: string;
  originCountry: any;
  industry: IndustryEnum;
  website: string;
  antiquity: AntiquityEnum
  tpv: TPVEnum;
  policy: boolean;
  country: string;
}

const LeadsForm = () => {
  const localeCode = useContext(LocaleContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [originCountry, setOriginCountry] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [value, setValue] = useState()
  const [countries, setCountry] = useState([])
  const { register, formState: { errors }, handleSubmit } = useForm<IFormInput>();
  const [formLang, setFormLang] = useState([])
  const [formLang2, setFormLang2] = useState([])
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const antiquityEsp = [ 
    'Menos de 1 año',
    'Entre 1 y 3 años',
    'Entre 3 y 5 años',
    'Más de 5 años',
  ]
  
  const antiquityEngl = [ 
    '1 year or less',
    'From 1 to 3 years',
    'From 3 to 5 years',
    'More than 5 years',
  ]
  
  const antiquitySelect = (path: string) => {
    useEffect(() => {
      if(path === 'es'){
        setFormLang([...antiquityEsp]) 
      } else if (path === 'en'){
        setFormLang([...antiquityEngl]) 
      }
    }, [path])
  }

  antiquitySelect(localeCode)

  const tpvEsp = [
    {
      option: 'Volumen transaccional en dolares mensuales',
      value: 'No eligió opción'
    },
    {
      option: 'Menos de 150,000 USD mensuales',
      value: 'Menos de 150,000 USD mensuales',
    },
    {
      option: 'Entre 150,000 y 200,000 USD mensuales',
      value: 'Entre 150,000 y 200,000 USD mensuales',
    },
    {
      option: 'Entre 200,000 y 500,000 USD mensuales',
      value: 'Entre 200,000 y 500,000 USD mensuales',
    },
    {
      option: 'Entre 500,000 y 2,000,000 USD mensuales',
      value: 'Entre 500,000 y 2,000,000 USD mensuales'
    },
    {
      option: 'Más de 2,000,000 USD mensuales',
      value: 'Más de 2,000,000 USD mensuales'
    },
  ]

  const tpvEngl = [
    {
      option: 'Transactional volume in monthly USD',
      value: 'No eligió opción'
    },
    {
      option: 'Less than 150,000 USD monthly',
      value: 'Menos de 150,000 USD mensuales',
    },
    {
      option: 'Between 150,000 and 200,000 USD monthly',
      value: 'Entre 150,000 y 200,000 USD mensuales',
    },
    {
      option: 'Between 200,000 and 500,000 USD monthly',
      value: 'Entre 200,000 y 500,000 USD mensuales',
    },
    {
      option: 'Between 500,000 and 2,000,000 USD monthly',
      value: 'Entre 500,000 y 2,000,000 USD mensuales'
    },
    {
      option: 'More than 2,000,000 USD monthly',
      value: 'Más de 2,000,000 USD mensuales'
    },
  ]

  const tpvSelect = (path: string) => {
    useEffect(() => {
      if(path === 'es'){
        setFormLang2([...tpvEsp]) 
      } else if (path === 'en'){
        setFormLang2([...tpvEngl]) 
      }
    }, [path])
  }

  tpvSelect(localeCode)

  const kushkiCountries:Array<any>=[ 
    {
      name:'Chile',
      value: 'Chile'
    },
    {
      name:'Colombia',
      value: 'Colombia'
    },
    {
      name:'Ecuador',
      value: "Ecuador"
    },
    {
      name: 'México',
      value: 'Mexico'
    },
    {
      name: 'Perú',
      value: 'Peru'
    }];

  const industriesList:Array<string> = [
    'Airlines',
    'Apps',
    'Construction',
    'Consulting',
    'Courrier',
    'Cripto & Forex',
    'Delivery',
    'Education',
    'Entretainment',
    'Finance',
    'Fitness',
    'Food & Beverage',
    'Gambling',
    'Gateway',
    'Govermnent',
    'Helthcare',
    'Insurance',
    'Media',
    'PSP',
    'Retail',
    'Technology',
    'Telecomunications',
    'Transportation',
    'Tourism',
    'Utilities'
  ];

  const countriesList:Array<any> = [
    {
      name: 'Antigua y Barbuda',
      value:'Antigua y Barbuda'
    },
    {
      name: 'Argentina',
      value:'Argentina'
    },
    {
      name: 'Bahamas',
      value:'Bahamas'
    },
    {
      name: 'Barbados',
      value:'Barbados'
    },
    {
      name: 'Belice',
      value:'Belice'
    },
    {
      name: 'Bolivia',
      value:'Bolivia'
    },
    {
      name: 'Brasil',
      value:'Brasil'
    },
    {
      name: 'Canadá',
      value:'Canada'
    },
    {
      name: 'Chile',
      value:'Chile'
    },
    {
      name: 'Colombia',
      value:'Colombia'
    },
    {
      name: 'Costa Rica',
      value:'Costa Rica'
    },
    {
      name: 'Cuba',
      value:'Cuba'
    },
    {
      name: 'Dominica',
      value:'Dominica'
    },
    {
      name: 'Ecuador',
      value:'Ecuador'
    },
    {
      name: 'El Salvador',
      value:'El Salvador'
    },
    {
      name: 'Granada',
      value:'Granada'
    },
    {
      name: 'Guatemala',
      value:'Guatemala'
    },
    {
      name: 'Guyana',
      value:'Guyana'
    },
    {
      name: 'Haití',
      value:'Haiti'
    },
    {
      name: 'Honduras',
      value:'Honduras'
    },
    {
      name: 'Jamaica',
      value:'Jamaica'
    },
    {
      name: 'México',
      value:'Mexico'
    },
    {
      name: 'Nicaragua',
      value:'Nicaragua'
    },
    {
      name: 'Panamá',
      value:'Panama'
    },
    {
      name: 'Paraguay',
      value:'Paraguay'
    },
    {
      name: 'Perú',
      value:'Peru'
    },
    {
      name: 'República Dominicana',
      value:'Republica Dominicana'
    },
    {
      name: 'San Cristóbal y Nieves',
      value:'San Cristobal y Nieves'
    },
    {
      name: 'San Vicente y las Granadinas',
      value:'San Vicente y las Granadinas'
    },
    {
      name: 'Santa Lucia',
      value:'Santa Lucia'
    },
    {
      name: 'Surinam',
      value:'Surinam'
    },
    {
      name: 'Trinidad y Tobago',
      value:'Trinidad y Tobago'
    },
    {
      name: 'Uruguay',
      value:'Uruguay'
    },
    {
      name: 'Venezuela',
      value:'Venezuela'
    }
  ];

  const operationCountries = (val: string) => {
    if (countries.includes(val)) {
      const index = countries.indexOf(val)
    countries.splice(index, 1)
    setCountry([...countries])
    } else {
      setCountry([...countries, val])
    }
  }


  const onSubmit: SubmitHandler<IFormInput> = data => {
    const date = new Date();
    const name = data.name.split(' ')
    console.log(data)
    if (data.policy) {
      const params = `
    name=${name} 
    &email=${data.email} 
    &phone=${value} 
    &company=${data.enterprise}
    &industry=${''}
    &country=${data.country}
    &url=${data.website} 
    &antiquity=${''}
    &date=${date.toISOString()}
    &tpv=${data.tpv}
    &GCLID=${getGCLID()}
    &policy=${data.policy}
    `
      axios.post('https://hooks.zapier.com/hooks/catch/1396001/bgog14u?' + params, {}, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          if (res.status === 200) {
            sendAnalyticsEvent();
            setIsOpen(true)
          }
        })
    }
  }

  useEffect(() => {
    getGCLID()
  }, [])

  //get GCLID
  function getGCLID() {
    const urlParams = new URLSearchParams(window.location.search);
    const gclid = urlParams.get('gclid');
    return gclid
  }

  const sendAnalyticsEvent = () => {
    if(typeof window !== 'undefined') {
      window.gtag('event', `Clic - Formulario`, {
        'event_category': 'Contacto Ventas',
        'event_label': `Contacto Ventas - Formulario`,
      })
    }
    return true
  }

  return (
    <div className="form-lead">
      <h1 className="form-lead-title"><FormattedMessage id="form.title" /></h1>
      <p className="form-lead-desc"><FormattedMessage id="form.description_1" /></p>
      <p className="form-lead-desc">
        <FormattedMessage 
          id="form.description_2" 
          values={defaultFormatValues}
        />
        <OutboundLink href="https://www.kushkimundial.com/">
          <FormattedMessage id="form.link-mundial" values={defaultFormatValues} />
        </OutboundLink>
      </p>
      <div className="container form-lead-container">
        <div className="row">
          <form id="kushki-form" className="form-lead-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="input-containers">
              <label className="col-md-6 col-lg-6 col-sm-12 input-label"><FormattedMessage id="form.questions.name" /> <b className="input-required">*</b></label>
              <div className="input-order col-md-6 col-lg-6 col-sm-12">
                <input placeholder="John Doe" {...register("name", { required: true, pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u })} className={errors.name ? 'input-error' : 'input'}/>
                {errors.name && <p className="error-message"><FormattedMessage id="form.errors.err-name" /></p>}
              </div>
            </div>
            <div className="input-containers">
              <label className="col-md-6 col-lg-6 col-sm-12 input-label"><FormattedMessage id="form.questions.email" /> <b className="input-required">*</b></label>
              <div className="input-order col-md-6 col-lg-6 col-sm-12">
              <input placeholder="mail@kushki.com" {...register("email", { required: true, pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,  })} className={errors.email ? 'input-error' : 'input'}/><br />
              {errors.email && <p className="error-message"><FormattedMessage id="form.errors.err-email" /></p>}
              </div>
            </div>
            <div className="input-containers">
              <label className="col-md-6 col-lg-6 col-sm-12 input-label"><FormattedMessage id="form.questions.phone" /> <b className="input-required">*</b></label>
              <div className="input-order col-md-6 col-lg-6 col-sm-12">
                <div className="phone-input">
                  <PhoneInput value={value} onChange={ setValue } defaultCountry="MX" placeholder="Enter phone number"/>
                </div>
              </div>
            </div>
            {/* <div className="line"></div> */}
            <div className="input-containers">
              <label className="col-md-6 col-lg-6 col-sm-12 input-label"><FormattedMessage id="form.questions.company" />  <b className="input-required">*</b></label>
              <div className="input-order col-md-6 col-lg-6 col-sm-12">
              <input className={errors.enterprise ? 'input-error' : 'input'} placeholder="Kushki Pagos" {...register("enterprise", {required: true /*, validate: (value) => {return value === "";}*/})} /><br />
              {errors.enterprise && <p className="error-message"><FormattedMessage id="form.errors.err-company" /></p>}
              </div>
            </div>
            <div className="input-containers">
              <label className="col-md-6 col-lg-6 col-sm-12 input-label"><FormattedMessage id="form.questions.website" />  <b className="input-required">*</b></label>
              <div className="input-order col-md-6 col-lg-6 col-sm-12">
                <input className={errors.website ? 'input-error' : 'input'} placeholder="www.kushkipagos.com" {...register("website", {required: true, pattern: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi})} /><br />
                {errors.website && <p className="error-message"><FormattedMessage id="form.errors.err-website" /></p>}
              </div>
            </div>
            {/* <div className="input-containers">
              <label className="col-md-6 col-lg-6 col-sm-12 input-label"><FormattedMessage id="form.questions.origin-country" /> <b className="input-required">*</b></label>
              <div className="input-order col-md-6 col-lg-6 col-sm-12">
              <select className={errors.originCountry ? 'input-error' : 'input'} {...register("originCountry", { required: "select one option" })}>
                  <option></option>
                  {countriesList.map((item) => (
                    <option key={item.name} value={item.value}>{item.name}</option>
                  ))}
                </select>
                {errors.originCountry && <p className="error-message"><FormattedMessage id="form.errors.err-origin" /></p>}
                <br />
                <OutboundLink href="https://www.kushkimundial.com/">
                  <FormattedMessage id="form.questions.no-country" values={defaultFormatValues} />
                </OutboundLink>
              </div>
            </div> */}
            <div className="input-containers">
              <label className="col-md-6 col-lg-6 col-sm-12 input-label"><FormattedMessage id="form.questions.operation-country" /> <b className="input-required">*</b></label><br />
              {/* <div className="checkbox-container col-md-6 col-lg-6">
                {kushkiCountries.map((item) => (
                  <div>
                    <input key={item} type="checkbox" value={item} onChange={e => operationCountries(e.target.value)}/>
                    <label >{item}</label>
                  </div>
                ))}
              </div> */}
              <select className={errors.originCountry ? 'input-error' : 'input'} {...register("country", { required: "select one option" })}>
                  <option></option>
                  {kushkiCountries.map((item) => (
                    <option key={item.name} value={item.value}>{item.name}</option>
                  ))}
                </select>
                {errors.originCountry && <p className="error-message"><FormattedMessage id="form.errors.err-origin" /></p>}
            </div>
            <br />
            <div className="input-containers">
              <label className="col-md-6 col-lg-6 col-sm-12 input-label"><FormattedMessage id="form.questions.tpv" /><b className="input-required">*</b></label>
              <div className="input-order col-md-6 col-lg-6 col-sm-12">
                <select className={errors.tpv ? 'input-error' : 'input'} {...register("tpv", { required: "select one option" })}>
                  <option></option>
                  {formLang2.map((tpv: any) => {
                    return <option key={tpv.option} value={tpv.value}>{tpv.option}</option>
                  })}
                </select>
                {errors.antiquity && <p className="error-message"><FormattedMessage id="form.errors.err-tpv" /></p>}
              </div>
            </div>
            <br />
            <div className='text-align-left full' style={{color: "#396fa6", width: "100%", marginBottom: "20px"}}>
              Campo obligatorio *
            </div>
            <div className="checkbox-box">
              <div className="r">
                <input type="checkbox" {...register("policy", { required: true })} />
              </div>
              <div className="r">
              Al dar clic en enviar, confirmo que he leído las <a href="#">Políticas de Privacidad y de Tratamiento de Datos Personales</a> Kushki y autorizo a Kushki para que utilice mis datos personales exclusivamente con la finalidad de atender los servicios y/o productos que estoy solicitando y, para que mis datos sean tratados de acuerdo con estas políticas. 
              </div>
            </div>
            {errors.policy && <p className="error-message" style={{marginBottom: "10px"}}><FormattedMessage id="form.errors.err-policy" /></p>}
            
            <ModalMessage modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} page="leadform" pathContext={url}/>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LeadsForm
