import React from 'react'
import IndexLayout from '../layouts';
import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';
import PanelLinks from '../components/PanelLinks/PanelLinks';
import FormLead from '../components/Form/FormLead';

interface FormPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const FormPage: React.FC <FormPageProps> = ({pathContext, location}) => {
  const content = () => (
    <>
      <SEO />
      
      <FormLead />

      {/* <PanelLinks /> */}

      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default FormPage